<template>
  <!-- navigation START -->
  <div id="nav">
    <div class="main-navigation">
      <!-- logo START -->
      <div class="logo logo-centered">
        <router-link :to="'/'"></router-link>
        <img
          width="132" height=""
          src="@/assets/images/logo-edeka-full.png"
          v-if="$route.name !== 'screensaver'"
        />
        <img
          width="132" height=""
          src="@/assets/images/logo-edeka-full.png"
          v-else
        /> <!-- @/assets/images/logo-syreta-full-white.png -->
      </div>
      <!-- logo END -->
    </div>
  </div>
  <!-- navigation END -->
</template>

<script>
export default {
  name: 'app-navigation',
  data() {
    return {
    };
  },
};
</script>
